// extracted by mini-css-extract-plugin
export var button = "Guide-module--button--16b5b";
export var container = "Guide-module--container--622de";
export var content = "Guide-module--content--8be15";
export var left = "Guide-module--left--ec209";
export var list = "Guide-module--list--42c57";
export var main = "Guide-module--main--ccc28";
export var notification = "Guide-module--notification--dc188";
export var notificationLink = "Guide-module--notificationLink--bee6d";
export var right = "Guide-module--right--d39f0";
export var text = "Guide-module--text--56695";