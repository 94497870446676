import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/SEO"
import { Guide } from "../../components/Guide/Guide"

interface Guide {
  title: string
  authors: string[]
  expertiseYears: number
  skills: string[]
  contactMailAddress: string
  aboutUs: { childMarkdownRemark: { html: string } }
  content: { childMarkdownRemark: { html: string } }
}

interface GuideQueryData {
  data: {
    contentfulGuide: Guide
  }
}

const GuidePage: React.FC<GuideQueryData> = ({ data }) => {
  const {
    title,
    expertiseYears,
    skills,
    content,
    contactMailAddress,
    authors,
    aboutUs,
  } = data.contentfulGuide

  return (
    <Layout>
      <div>
        <SEO title={title} />
        <Guide
          title={title}
          expertiseYears={expertiseYears}
          skills={skills}
          contactEmailAddress={contactMailAddress}
          aboutUs={aboutUs.childMarkdownRemark.html}
          content={content.childMarkdownRemark.html}
          authors={authors}
        />
      </div>
    </Layout>
  )
}
export default GuidePage

export const query = graphql`
  query ($slug: String) {
    contentfulGuide(slug: { eq: $slug }) {
      title
      authors
      expertiseYears
      skills
      contactMailAddress
      aboutUs {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
