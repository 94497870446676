import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import spiralNotepad from "../../images/vacancyPage/spiralNotepad.svg"
import wavingHand from "../../images/vacancyPage/wavingHand.svg"
import BackButton from "../BackButton/BackButton"
import Button from "../Button"
import Notification from "../Notification"
import * as styles from "./Guide.module.scss"
import { KeyInfo } from "../Vacancy/KeyInfo/KeyInfo"
import { Title } from "../Vacancy/Title/Title"
import { InfoSection } from "../Vacancy/InfoSection/InfoSection"

type GuideProps = {
  title: string
  expertiseYears: number
  skills: string[]
  contactEmailAddress: string
  aboutUs: string
  content: string
  authors: string[]
}

export const Guide: React.FC<GuideProps> = ({
  title,
  expertiseYears,
  skills,
  contactEmailAddress,
  aboutUs,
  content,
  authors,
}) => {
  const links = [<BackButton />]

  return (
    <div className={styles.main}>
      <section>
        <div className={styles.container}>
          <Title mainText={title} secondaryText="Expert Tips" links={links} />
          <div className={styles.content}>
            <div className={styles.left}>
              <KeyInfo
                title="Years of Expertise"
                details={`${expertiseYears} years`}
              ></KeyInfo>
              <KeyInfo title="Skills" details={skills.join(", ")}></KeyInfo>
              <KeyInfo title="Authors" details={authors.join(", ")}></KeyInfo>
              <Button
                text="Contact Us"
                color="violet"
                onClick={() =>
                  (window.location.href = `mailto:${contactEmailAddress}`)
                }
                className={styles.button}
              />
            </div>
            <div className={styles.right}>
              <InfoSection icon={wavingHand} title="About Us">
                <div
                  className={styles.text}
                  dangerouslySetInnerHTML={{ __html: aboutUs }}
                />
              </InfoSection>
              <InfoSection
                icon={spiralNotepad}
                title="Issues and How to Solve Them"
              >
                <div
                  className={styles.list}
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </InfoSection>
            </div>
          </div>
          <Notification
            className={styles.notification}
            link={
              <AnchorLink to="/#join-us">
                <h5 className={styles.notificationLink}>Why join our team?</h5>
              </AnchorLink>
            }
          />
        </div>
      </section>
    </div>
  )
}
